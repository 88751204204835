import { FC, ReactNode } from 'react';
import styled from 'styled-components';
import media from "styled-media-query";
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';

export const Card: FC<{}> = ({ children }) => {
    return (
        <Container>
            {children}
        </Container>
    )
}

export const StickyCard: FC<{}> = ({ children }) => {
    return (
        <StickyContainer>
            {children}
        </StickyContainer>
    )
}

export const Tile: FC<{}> = ({ children }) => {
    return (
        <TileBox>
            {children}
        </TileBox>
    )
}

export const AddressBox = (props: { children: ReactNode, onClick: any, style: any }) => {
    return (
        <Box onClick={props.onClick} style={props.style}>
            <AddressBadge />
            {props.children}
        </Box>
    )
}

export const ShippingBox = (props: { children: ReactNode, onClick: any, active: boolean }) => {
    return (
        <RadioBox onClick={props.onClick} style={{ outline: props.active ? '3px solid #FCC201' : '' }}>
            <div className="icon">
                {props.active ? <RadioButtonCheckedIcon /> : <RadioButtonUncheckedIcon />}
            </div>
            {props.children}
        </RadioBox>
    )
}

export const CardModal: FC<{}> = ({ children }) => {
    return (
        <ModalBox>
            {children}
        </ModalBox>
    )
}

export const CardProductBox: FC<{}> = ({ children }) => {
    return (
        <CardBox>
            {children}
        </CardBox>
    )
}

type ExtrasState = {
    children: ReactNode,
    title: string,
    cover: string,
    description: string,
    content: string,
    __last_update: string,
}

export const TipsTile = ({ children, title, cover, description, content, __last_update }: ExtrasState) => {
    return (
        <TipsTileBox>
            <div className="cover">
                <img src={cover} alt="" />
            </div>
            <div className="content">
                <h6>{title}</h6>
                <p className="subtitle">{__last_update}</p>
            </div>
            {children}
        </TipsTileBox>
    )
}

export const NewsTile = ({ children, title, cover, description, content, __last_update }: ExtrasState) => {
    return (
        <NewsTileBox>
            <div className="cover">
                <img src={cover} alt="" />
            </div>
            <div className="content">
                <h6>{title}</h6>
                <p className="subtitle">{__last_update}</p>
                <p className="desc" dangerouslySetInnerHTML={{ __html: content }}></p>
                {children}
            </div>
        </NewsTileBox>
    )
}

export const VideoTile = ({ children, title, cover, description, content, __last_update }: ExtrasState) => {
    return (
        <VideoTileBox>
            <div className="cover">
                <img src={cover} alt="" />
                <div className="video-overlay">
                    <PlayCircleFilledIcon></PlayCircleFilledIcon>
                </div>
            </div>
            <div className="content">
                <h6>{title}</h6>
                <p className="subtitle">{__last_update}</p>
            </div>
            {children}
        </VideoTileBox>
    )
}

export const StoryGrid = ({ children, title, cover, description, content, __last_update }: ExtrasState) => {
    return (
        <StoryBox>
            <div className="cover">
                <img src={cover} alt="" />
            </div>
            <div className="content">
                <h6>{title}</h6>
                <p className="subtitle">{__last_update}</p>
            </div>
            {children}
        </StoryBox>
    )
}

const Container = styled.div`
    background-color: rgb(0, 0, 0, .025);
    border-radius: 10px;
    padding: 25px;

    ${media.lessThan("medium")`
        padding: 15px; 
    `}
`

const StickyContainer = styled(Container)`
    position: sticky;
    top: 50px;
`

export const Box = styled.div`
    padding: 20px;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 7px;
    background-color: transparent;
    position: relative;
    text-transform: capitalize;
`

const AddressBadge = styled.div`
    position: absolute;
    top: 1rem;
    left: 0;
    width: 5px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    height: 2rem;
    background: #F4C749;
`

export const PinpointStatus = styled.div`
    margin-top: 0.5rem;
    display: flex;
    gap: 5px;
    align-items: center;
`

const ModalBox = styled(Box)`
    background-color: #FFF;
    width: calc(100% - 100px);
    height: auto;
    max-width: 800px;
    max-height: calc(100vh - 100px);
    border-radius: 18px;
    padding: 20px 30px;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 5px 9px;
    overflow-y: scroll;
    text-transform: none;
    
    ::-webkit-scrollbar {
        width: 0;  /* Remove scrollbar space */
        background: transparent;  /* Optional: just make scrollbar invisible */
    }
    
    ${media.lessThan("medium")`
        max-height: calc(100% - 100px);
        overflow-y: scroll;
    `}
`

const CardBox = styled(Container)`
    padding: 5px;
    border-radius: 15px;
    position: relative;
    background-color: rgb(0, 0, 0, .025);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 30px;

    ${media.lessThan("small")`
        border-radius: 8px;
        padding: 10px;
    `}
`

const TileBox = styled.div`
    display: flex;
    justify-content: flex-start;
    padding: 0px;
    transition: all ease-in-out 0.2s;

    :hover {
        background-color: rgb(0, 0, 0, .035);
    }
`

const TipsTileBox = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5em;

    & .cover {
        height: 170px;
        width: 170px;
    
        ${media.lessThan("small")`
            height: 120px;
            width: 120px;
        `}

        & img {
            height: 170px;
            width: 170px;
            object-fit: cover;
            
            ${media.lessThan("small")`
                height: 120px;
                width: 120px;
            `}
        }
    }

    & .content {
        max-width: calc(100% - 170px - 0.5rem);
        padding: 0 1rem;
        overflow: hidden;

        ${media.lessThan("small")`
            padding: 0 0.5rem;
        `}

        & h6 {
            font-size: 1.5rem;
            margin: 0;

            ${media.lessThan("medium")`
                font-size: 1.25rem;
            `}
        }

        & .subtitle {
            margin: 0.5rem 0;
            color: #888;

            ${media.lessThan("medium")`
                font-size: 0.875rem;
            `}
        }
    }
`

const NewsTileBox = styled(TipsTileBox)`
    flex-direction: column;
    align-items: start;
    position: relative;

    & .cover {
        width: 100%;
        height: 350px;

        ${media.lessThan("small")`
            height: 200px;
        `}

        & img {
            width: 100%;
            height: 100%;

            ${media.lessThan("small")`
                height: 200px;
            `}
        }
    }

    & .content {
        max-width: 100%;
        padding: 1rem 0rem;
        overflow: hidden;

        & .subtitle {
            margin-top: 1.5rem;
            margin-bottom: 0rem;
        }

        & .desc {
            color: #333;
            line-height: 1.5;
        }
    }
`

const VideoTileBox = styled(NewsTileBox)`
    
    ${media.lessThan("medium")`
        width: 250px;
    `}

    & .cover {
        width: 100%;
        height: 200px;
        position: relative;

        ${media.lessThan("medium")`
            width: 250px;
        `}

        & .video-overlay {
            opacity: 0;
            position: absolute;
            width: 100%;
            height: 100%;
            background-color: rgb(51, 51, 51, 0.3);
            top: 0;
            left: 0;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: all ease-in-out 0.2s;

            ${media.lessThan("medium")`
                opacity: 1;
            `}
            
            & svg {
                font-size: 2.5rem;
                color: #FFF;
            }
        }

        img {
            width: 100%;
            height: 200px;
        }

        :hover .video-overlay {
            opacity: 1
        }
    }

    & .content {
        padding: 0.5rem 0;
        overflow: hidden;
        
        ${media.lessThan("medium")`
            display: none;
        `}
        
        & h6 {
            font-size: 1rem;
            line-height: 1;
        }

        & .subtitle {
            margin-top: 0.5rem;
            font-size: 0.875rem;
        }
    }
`

const StoryBox = styled(TipsTileBox)`
    flex-direction: column;
    align-items: flex-start;

    .cover {
        width: 100%;
        
        ${media.lessThan("small")`
            height: 150px;
        `}

        img {
            width: 100%;

            ${media.lessThan("small")`
                height: 150px;
            `}
        }
    }

    .content {
        max-width: 100%;
        padding: 0.5rem 1rem;
    }
`

export const DataBox = styled.div<{ pd: string }>`
    padding: ${props => props.pd};
`

const RadioBox = styled(Box)`
    position: relative;
    padding-left: 50px;
    cursor: pointer;

    .icon {
        position: absolute;
        left: 15px;
        color: #F4C749;
    }
`

export const ScrolledGrid = styled.div`
    width: 100%;
    position: relative;
    
    ${media.greaterThan("medium")`
        overflow-x: hidden;
        overflow-y: scroll;
        height: 80vh;
        scrollbar-width: unset;

        ::-webkit-scrollbar {
            display: none;
        }
    `}
`
