import { Fragment, useState, useEffect, useContext } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Container, Typography, Grid, Snackbar, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { ActionButton, ButtonGroup, EcomIcon, LeftButtonGroup } from './DynamicButton';
import Skeleton from '../skeleton/Skeleton';
import AxiosInstance from '../utils/axiosInstance';

import { Link, useParams } from 'react-router-dom';
import NumberFormat from 'react-number-format';
import Cookies from 'universal-cookie';
import axios from 'axios';
import { BadgeContext } from '../state/context';
import { ModalHeading, ProductHeading } from './heading/Heading';
import { PromoDesc, PromoPrice, Price, LargeImageContainer, ProductCartPop } from './container/ProductContainer';

import { ProductState, RouteState } from '../state/state';
import { DataBox } from './container/Card';
import { ModalNoOverflow } from './container/Modal';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            padding: '30px 0',
            minHeight: '50vh'
        },
        link: {
            textDecoration: 'none',
            fontWeight: 'bold',
            color: '#333',
            '&:hover': {
                textDecoration: 'underline',
            }
        },
    }),
);

const Detail = () => {
    const params = useParams<RouteState>();
    const classes = useStyles();
    const cookies = new Cookies();

    const [product, setProduct] = useState<ProductState>();
    const [loading, setLoading] = useState(false);
    const [addedcart, setAddedcart] = useState({ open: false, message: "" });
    const [snackbar, setSnackbar] = useState({ open: false, message: "" });

    const context = useContext(BadgeContext);

    const url = process.env.REACT_APP_MINUMMINUM_PUBLIC_API + '/products/' + params.id;

    const addWishlist = async () => {
        let res = await AxiosInstance.post('/wishlist', {
            "id": 0,
            "productid": Number(params.id),
        });

        if (res.status === 200 && res.data.Status === true) {
            console.log(res.data.Message);

            let updateBadge = await AxiosInstance.get('/customers/myprofile');

            if (updateBadge.status === 200 && updateBadge.data.count > 0) {
                context.dispatch({
                    type: 'GENERATE',
                    payload: {
                        wishlist_qty: updateBadge.data.result[0].qty_wishlist,
                        cart_qty: updateBadge.data.result[0].qty_cart,
                    }
                });
                setSnackbar({ open: true, message: product?.display_name + " has been successfully added to your wishlist." });
            }
        }
        else {
            console.log(res.data.Message);
            setSnackbar({ open: true, message: product?.display_name + " is already on your wishlist." });
        }
    }

    const addCart = async () => {
        let res = await AxiosInstance.post('sales_detail/add_cart', {
            "productid": Number(params.id),
            "price": product?.promo_price !== 0 ? product?.promo_price : product?.list_price,
            "quantity": 1
        });

        if (res.status === 200 && res.data.Status === true) {
            console.log(res.data.Message);

            let updateBadge = await AxiosInstance.get('/customers/myprofile');

            if (updateBadge.status === 200 && updateBadge.data.count > 0) {
                context.dispatch({
                    type: 'GENERATE',
                    payload: {
                        wishlist_qty: updateBadge.data.result[0].qty_wishlist,
                        cart_qty: updateBadge.data.result[0].qty_cart,
                    }
                });
                setAddedcart({ open: true, message: product?.display_name + " has been successfully added to your cart." });
            }
        }
        else {
            console.log(res.data.Message);
        }
    }

    const handleAddWishlist = () => {
        if (cookies.get('token')) {
            addWishlist();
        }
        else {
            setSnackbar({ open: true, message: "You must logged in to add wishlist." });
        }
    }

    const handleAddCart = () => {
        if (cookies.get('token')) {
            addCart();
        }
        else {
            setSnackbar({ open: true, message: "You must logged in to add cart." });
        }
    }

    useEffect(() => {
        const getProduct = async () => {
            try {
                await axios.get(url).then(res => {
                    setProduct(res.data.result[0]);
                })
            }
            catch (error) {
                console.log(error);
            }
            setLoading(true);
        }
        getProduct();
        return () => {
            setLoading(false);
        }
    }, [url]);
    
    return (
        <div>
            {!loading ? <Skeleton type="productDetail"></Skeleton> :
                product === undefined || Object(product).length === 0 ?
                    <Typography variant="subtitle1">There is no product in this parameter</Typography>
                    : <Grid container spacing={3}>
                        <Grid item xs={12} md={4}>
                            <LargeImageContainer>
                                <img src={product.image_1024_url} alt="" />
                            </LargeImageContainer>
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <ProductHeading text={product.display_name} />
                            <PromoDesc promo={String(product.promo_description)} promo_price={product.promo_price} type="large"></PromoDesc>
                            {(() => {
                                if (product.promo_price !== 0) {
                                    return (
                                        <>
                                            <PromoPrice type="large"><span>{product.currency_id[1]} <NumberFormat value={product.list_price} thousandSeparator="." decimalSeparator="," displayType={'text'} /></span></PromoPrice>
                                            <Price type="large">{product.currency_id[1]} <NumberFormat value={product.promo_price} thousandSeparator="." decimalSeparator="," displayType={'text'} /></Price>
                                        </>
                                    )
                                }
                                else {
                                    return (
                                        <Price type="large">{product.currency_id[1]} <NumberFormat value={product.list_price} thousandSeparator="." decimalSeparator="," displayType={'text'} /></Price>
                                    )
                                }
                            })()}
                            <Typography variant="subtitle1" gutterBottom>
                                Weight: {product.weight} kg <br />
                                Volume: {product.volume_uom[0]} {product.volume_uom[1]} <br />
                                Category :
                                {product.categ_id[1].split(" / ").length > 0 ? product.categ_id[1].split(" / ").filter((item: string, index: number) => index !== 0).map((item: string, index: number) =>
                                    <Fragment key={index}>
                                        &nbsp;<Link key={index} to={`/collection/${product.categ_id[1].split(" / ")[1] !== undefined ? product.categ_id[1].split(" / ")[1].toLowerCase() : product.categ_id[1].toLowerCase()}/${product.public_categ_ids[index] !== undefined ? product.public_categ_ids[index] : product.public_categ_ids[0]}`} className={classes.link}><span style={{ textTransform: 'capitalize', }}>{item}</span></Link>
                                        {Number(product.categ_id[1].split(" / ").length - 2) === Number(index) ? "" : ","}
                                    </Fragment>
                                ) : <Link to={`/collection/${product.categ_id[1].split(" / ")[1] !== undefined ? product.categ_id[1].split(" / ")[1].toLowerCase() : product.categ_id[1].toLowerCase()}/${product.public_categ_ids[0] ? product.public_categ_ids[0] : ''}`} className={classes.link}><span style={{ textTransform: 'capitalize', }}>{product.categ_id[1].split(" / ")[1] !== undefined ? product.categ_id[1].split(" / ")[product.categ_id[1].split(" / ").length - 1] : product.categ_id[1]}</span></Link>
                                }
                            </Typography>
                            <DataBox pd="0.5rem 0">
                                <Typography variant="body1" gutterBottom>
                                    {String(product.description) !== "false" && String(product.description) !== "" ? product.description.replace(/(<([^>]+)>)/gi, "") : product.description}
                                </Typography>
                            </DataBox>
                            {(product.blibli_url !== false || product.tokopedia_url !== false || product.gofood_url !== false || product.grabfood_jkt_url !== false || product.grabfood_manado_url !== false) && <Typography variant="body1" gutterBottom>Also available on: </Typography>}
                            <LeftButtonGroup>
                                {String(product.blibli_url) !== "false" && <EcomIcon type="blibli" url={String(product.blibli_url)} />}
                                {String(product.tokopedia_url) !== "false" && <EcomIcon type="tokped" url={String(product.tokopedia_url)} />}
                                {String(product.grabfood_jkt_url) !== "false" && <EcomIcon type="grabfood_jkt" url={String(product.grabfood_jkt_url)} />}
                                {String(product.grabfood_manado_url) !== "false" && <EcomIcon type="grabfood_mnd" url={String(product.grabfood_manado_url)} />}
                                {String(product.gofood_url) !== "false" && <EcomIcon type="gofood" url={String(product.gofood_url)} />}
                            </LeftButtonGroup>
                            <ButtonGroup>
                                <ActionButton onClick={handleAddWishlist} label="Add to Favorite" type="secondary" />
                                <ActionButton onClick={handleAddCart} label="Add to Cart" type="primary" />
                            </ButtonGroup>
                        </Grid>
                        <ModalNoOverflow open={addedcart.open} onClose={() => setAddedcart({ open: false, message: "" })}>
                            <ModalHeading text="Successfully added" />
                            <ProductCartPop
                                display_name={product.display_name}
                                currency={product.currency_id[1]}
                                price={product.promo_price !== 0 ? product.promo_price : product.list_price}
                                image={product.image_128}
                                url={`/product/${product.categ_id[1].split(" / ")[1] !== undefined ? product.categ_id[1].split(" / ")[1].toLowerCase() : product.categ_id[1].toLowerCase()}/${product.id}`}
                            >
                            </ProductCartPop>
                        </ModalNoOverflow>
                        <Snackbar
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            open={snackbar.open}
                            autoHideDuration={5000}
                            onClose={() => setSnackbar({ open: false, message: "" })}
                            message={snackbar.message}
                            action={
                                <>
                                    <IconButton aria-label="close snackbar" disableRipple onClick={() => setSnackbar({ open: false, message: "" })}>
                                        <CloseIcon fontSize="small" style={{ color: '#FFF' }} />
                                    </IconButton>
                                </>
                            }
                        />
                    </Grid>
            }
        </div>
    )
}

const ProductDetails = () => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Container>
                <Detail />
            </Container>
        </div>
    );
}

export default ProductDetails;