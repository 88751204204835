import styled from 'styled-components';

import CircularProgress from '@material-ui/core/CircularProgress';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Typography from '@material-ui/core/Typography';

export const Notification = (props: { text: string, type: string }) => {
    return (
        <Container>
            {props.type === 'loading' && <CircularProgress size={20} className="icon" /> }
            {props.type === 'info' && <InfoOutlinedIcon className="icon" /> }
            <Typography className="text">{props.text}</Typography>
        </Container>
    )
}

const Container = styled.div`
    padding: 20px 15px;
    background: rgba(201, 139, 18, 0.15);
    margin-bottom: 0rem;
    border-radius: 8px;
    display: flex;
    align-items: flex-start;

    .icon {
        color: #C98B12;
        margin-right: 1rem;;
    }

    .text {
        color: #C98B12;
    }
`