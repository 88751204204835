import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Container, Typography, Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            marginTop: '20px',
            backgroundColor: '#FAF4E4',
            borderBottom: '10px solid #FCC201',
            padding: '40px',
        },
        copyrightContainer: {
            marginTop: '20px',
            textAlign: 'center',
        },
        title: {
            fontWeight: 'bold',
        },
        link: {
            textDecoration: 'none',
            color: '#333',
            '&:hover': {
                textDecoration: 'underline',
            }
        },
        paymentImageContent: {
            display: 'flex',
            marginTop: '0.5rem',
            width: '200px',
        }
    }),
);

const Footer = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Container>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={3}>
                        <Typography variant="h6" className={classes.title} gutterBottom>
                            Follow Us
                        </Typography>
                        <Typography variant="h6" className={classes.title} gutterBottom>
                            Get the app
                        </Typography>
                        <Typography variant="h6" className={classes.title} gutterBottom>
                            Payment Option
                        </Typography>
                        <picture>
                            <source className={classes.paymentImageContent} srcSet="/assets/images/payment-image.webp" type="image/webp" />
                            <source className={classes.paymentImageContent} srcSet="/assets/images/payment-image.png" type="image/png" />
                            <img width="200" height="49" className={classes.paymentImageContent} src="/assets/images/payment-image.png" alt="Order Now" />
                        </picture>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Typography variant="h6" className={classes.title} gutterBottom>
                            About
                        </Typography>
                        <Link to="/pages/about_us" className={classes.link}>
                            <Typography variant="subtitle1" gutterBottom>
                                About us
                            </Typography>
                        </Link>
                        <Link to="/collection/merchandise/2" className={classes.link}>
                            <Typography variant="subtitle1" gutterBottom>
                                Gift
                            </Typography>
                        </Link>
                        <Link to="/pages/faq" className={classes.link}>
                            <Typography variant="subtitle1" gutterBottom>
                                FAQ
                            </Typography>
                        </Link>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Typography variant="h6" className={classes.title} gutterBottom>
                            Get in touch
                        </Typography>
                        <Link to="/pages/support" className={classes.link}>
                            <Typography variant="subtitle1" gutterBottom>
                                Support
                            </Typography>
                        </Link>
                        <Link to="/pages/Accesibility" className={classes.link}>
                            <Typography variant="subtitle1" gutterBottom>
                                Accesibility
                            </Typography>
                        </Link>
                        <Link to="/pages/Returns" className={classes.link}>
                            <Typography variant="subtitle1" gutterBottom>
                                Returns
                            </Typography>
                        </Link>
                        <Link to="/pages/General" className={classes.link}>
                            <Typography variant="subtitle1" gutterBottom>
                                General
                            </Typography>
                        </Link>
                        <Link to="/pages/Media" className={classes.link}>
                            <Typography variant="subtitle1" gutterBottom>
                                Media
                            </Typography>
                        </Link>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <Typography variant="h6" className={classes.title} gutterBottom>
                            Partner with us
                        </Typography>
                        <Link to="/pages/for_business" className={classes.link}>
                            <Typography variant="subtitle1" gutterBottom>
                                For business
                            </Typography>
                        </Link>
                        <Link to="/pages/Retailers" className={classes.link}>
                            <Typography variant="subtitle1" gutterBottom>
                                Retailers
                            </Typography>
                        </Link>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1" gutterBottom className={classes.copyrightContainer}>
                            Privacy Policy | Terms of service | © Minumminum.com All Right Reserved
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}

export default Footer;