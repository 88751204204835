import { useState } from "react";
import GoogleMapReact from 'google-map-react';
import styled from 'styled-components';
import RoomIcon from '@material-ui/icons/Room';

const SimpleMap = (props: { name: string, lat: number, lng: number, onClick: any }) => {

    const [coordinate, setCoordinate] = useState({ lat: props.lat, lng: props.lng });

    const Marker = ({ lat, lng }: any) => {
        return (
            <CustomMarker>
                <MarkerContainer>
                    <b>{props.name}</b>
                    <span>Latitude: {lat}</span>
                    <span>Longitude: {lng}</span>
                </MarkerContainer>
                <RoomIcon style={{ fontSize: '2.5rem', filter: 'drop-shadow(0px 6px 3px rgb(0 0 0 / 0.1))' }} />
            </CustomMarker>
        )
    }

    const onClickCoordinate = (e: any) => {
        setCoordinate({ lat: e.lat, lng: e.lng });
        props.onClick({ lat: e.lat, lng: e.lng });
    }

    return (
        <div style={{ height: '400px', width: '100%' }}>
            <GoogleMapReact
                bootstrapURLKeys={{ key: 'AIzaSyASw9mLrsvwtzl8axIUK2pnqunGIQyE-x8' }}
                defaultCenter={{ lat: -6.17539239552917, lng: 106.82712061142473 }}
                center={{ lat: props.lat !== 0 ? props.lat : -6.17539239552917, lng: props.lng !== 0 ? props.lng : 106.82712061142473 }}
                defaultZoom={14}
                yesIWantToUseGoogleMapApiInternals
                onClick={(e: any) => onClickCoordinate(e)}
                options={{
                    disableDefaultUI: true,
                    mapTypeControl: true,
                    streetViewControl: true,
                    styles: [{ featureType: 'poi', elementType: 'labels', stylers: [{ visibility: 'on' }] }],
                }}
            >
                {props.lat !== 0 && props.lng !== 0 ?
                    <Marker
                        lat={coordinate.lat}
                        lng={coordinate.lng}
                    /> : ''}
            </GoogleMapReact>
        </div>
    );
}

export const Maps = (props: { name: string, lat: number, lng: number, onClick: any }) => {

    return (
        <MapContainer>
            <SimpleMap name={props.name} lat={props.lat} lng={props.lng} onClick={props.onClick} />
        </MapContainer>
    )
}

const MapContainer = styled.div`
    max-height: 400px;
`

const CustomMarker = styled.div`
    height: auto;
    position: absolute;
    bottom: 0;
    left: -125px;
    color: #C98B12;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    gap: 5px;
`

const MarkerContainer = styled.div`
    width: 100%;
    padding: 10px 15px;
    border-radius: 5px;
    background-color: #FFF;
    font-family: 'Courier Prime', Courier, monospace;
    font-size: 0.75rem;
    color: #333;
    display: flex;
    flex-direction: column;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 5px 9px;

    b {
        margin-bottom: 0.5rem;
    }

    span {
        white-space: nowrap;
    }
`