import styled from 'styled-components';
// import media from "styled-media-query";
import Grid from '@material-ui/core/Grid';

import './Skeleton.css';
import { ButtonGroup, LeftButtonGroup } from '../components/DynamicButton';


const SkeletonProduct = () => {
    return (
        <ProductSkeletonWrapper>
            <PISkeleton width="230px" height="230px" />
            <BoxContent>
                <Grid container spacing={2} justifyContent="center">
                    <Grid item xs={10}><PSkeleton width="100%" /></Grid>
                    <Grid item xs={10}><PSkeleton width="100%" /></Grid>
                    <Grid item xs={10}><PSkeleton width="100%" /></Grid>
                    <Grid item xs={10}><ButtonSkeleton width="100%" /></Grid>
                </Grid>
            </BoxContent>
            <div className="shimmerWrapper">
                <div className="shimmerContent"></div>
            </div>
        </ProductSkeletonWrapper>
    )
}

const SkeletonBlog = () => {
    return (
        <StorySkeletonWrapper>
            <PISkeleton width="150px" height="150px" />
            <BoxContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}><TitleSkeleton width="100%" /></Grid>
                    <Grid item xs={12}><PSkeleton width="70%" /></Grid>
                </Grid>
            </BoxContent>
            <div className="shimmerWrapper">
                <div className="shimmerContent"></div>
            </div>
        </StorySkeletonWrapper>
    )
}

const SkeletonBlogDetail = () => {
    return (
        <TransparentSkeletonWrapper>
            <TitleSkeleton width="100%" />
            <SubtitleSkeleton width="40%" />
            <BoxContent>
                <ImageSkeleton width="100%" height="300px" />
            </BoxContent>
            <BoxContent>
                <PSkeleton width="100%" />
                <PSkeleton width="100%" />
                <PSkeleton width="100%" />
                <PSkeleton width="40%" />
            </BoxContent>
            <BoxContent>
                <PSkeleton width="100%" />
                <PSkeleton width="100%" />
                <PSkeleton width="70%" />
            </BoxContent>
            <div className="shimmerWrapper">
                <div className="shimmerContent"></div>
            </div>
        </TransparentSkeletonWrapper>
    )
}

const SkeletonProductDetail = () => {
    return (
        <TransparentSkeletonWrapper>
            <Grid container spacing={2}>
                <Grid item xs={12} md={4}>
                    <ImageSkeleton width="100%" height="250px" />
                </Grid>
                <Grid item xs={12} md={8}>
                    <TitleSkeleton width="70%" />
                    <SubtitleSkeleton width="40%" />
                    <BoxContent>
                        <SubtitleSkeleton width="30%" />
                        <SubtitleSkeleton width="25%" />
                    </BoxContent>
                    <BoxContent>
                        <PSkeleton width="100%" />
                        <PSkeleton width="100%" />
                        <PSkeleton width="70%" />
                    </BoxContent>
                    <ButtonGroup>
                        <TitleSkeleton width="15%" />
                        <TitleSkeleton width="15%" />
                    </ButtonGroup>
                </Grid>
                <div className="shimmerWrapper">
                    <div className="shimmerContent"></div>
                </div>
            </Grid>
        </TransparentSkeletonWrapper>
    )
}

const SkeletonNews = () => {
    return (
        <NoPaddingSW>
            <ImageSkeleton width="100%" height="300px" />
            <BoxContent>
                <TitleSkeleton width="100%" />
                <SubtitleSkeleton width="40%" />
            </BoxContent>
            <BoxContent>
                <PSkeleton width="100%" />
                <PSkeleton width="100%" />
                <PSkeleton width="100%" />
                <PSkeleton width="40%" />
            </BoxContent>
            <div className="shimmerWrapper">
                <div className="shimmerContent"></div>
            </div>
        </NoPaddingSW>
    )
}

const SkeletonTipsTricks = () => {
    return (
        <NoPaddingSW>
            <Grid container spacing={3} alignItems="center" >
                <Grid item xs={4} sm={3}>
                    <ImageSkeleton width="100%" height="120px" />
                </Grid>
                <Grid item xs={8} sm={9}>
                    <TitleSkeleton width="90%" />
                    <SubtitleSkeleton width="40%" />
                </Grid>
            </Grid>
            <div className="shimmerWrapper">
                <div className="shimmerContent"></div>
            </div>
        </NoPaddingSW>
    )
}

const SkeletonCart = () => {
    return (
        <SkeletonWrapper>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <ImageSkeleton width="100%" height="200px" />
                </Grid>
                <Grid item xs={8}>
                    <PSkeleton width="60%" />
                    <PSkeleton width="100%" />
                    <SubtitleSkeleton width="60%" />
                    <BoxContent>
                        <LeftButtonGroup>
                            <SmallBS width="1.5rem" height="1.5rem" />
                            <SmallBS width="1.5rem" height="1.5rem" />
                            <SmallBS width="1.5rem" height="1.5rem" />
                        </LeftButtonGroup>
                    </BoxContent>
                    <PSkeleton width="40%" />
                    <div className="shimmerWrapper">
                        <div className="shimmerContent"></div>
                    </div>
                </Grid>
            </Grid>
        </SkeletonWrapper>
    )
}

const SkeletonCartBox = () => {
    return (
        <SkeletonWrapper>
            <TitleSkeleton width="60%" />
            <BoxContent />
            <BoxContent>
                <Grid container spacing={2} justifyContent="space-between">
                    <Grid item xs={6}><PSkeleton width="100%" /></Grid>
                    <Grid item xs={6}><PSkeleton width="100%" /></Grid>
                    <Grid item xs={6}><PSkeleton width="100%" /></Grid>
                    <Grid item xs={6}><PSkeleton width="100%" /></Grid>
                </Grid>
            </BoxContent>
            <BoxContent>
                <Grid container spacing={2} justifyContent="space-between">
                    <Grid item xs={6}><PSkeleton width="100%" /></Grid>
                    <Grid item xs={6}><PSkeleton width="100%" /></Grid>
                </Grid>
            </BoxContent>
            <BoxContent>
                <PSkeleton width="100%" />
                <PSkeleton width="70%" />
            </BoxContent>
            <ButtonSkeleton width="100%" />
            <div className="shimmerWrapper">
                <div className="shimmerContent"></div>
            </div>
        </SkeletonWrapper>
    )
}

const SkeletonBanner = () => {
    return (
        <SkeletonWrapper style={{ padding: '50px' }}>
            <Grid container>
                <Grid item xs={10}>
                    <Grid container justifyContent="space-between" style={{ height: '100%' }}>
                        <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}><TitleSkeleton width="50%"></TitleSkeleton></Grid>
                        <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}><TitleSkeleton width="80%"></TitleSkeleton></Grid>
                        <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}><TitleSkeleton width="50%"></TitleSkeleton></Grid>
                    </Grid>
                </Grid>
                <Grid item xs={2}>
                    <ImageSkeleton width="160px" height="160px"></ImageSkeleton>
                </Grid>
            </Grid>
            <div className="shimmerWrapper">
                <div className="shimmerContent"></div>
            </div>
        </SkeletonWrapper>
    )
}

const SkeletonProductCategory = () => {
    return (
        <TransparentSkeletonWrapper>
            <LeftButtonGroup>
                <TitleSkeletonDarker width="100px" />
                <TitleSkeletonDarker width="100px" />
                <TitleSkeletonDarker width="100px" />
            </LeftButtonGroup>
            <div className="shimmerWrapper">
                <div className="shimmerContent"></div>
            </div>
        </TransparentSkeletonWrapper>
    )
}

const SkeletonInvoice = () => {
    return (
        <Grid item xs={12}>
            <SkeletonWrapper>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <TitleSkeleton width="40%"></TitleSkeleton>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <ImageSkeleton width="250px" height="250px"></ImageSkeleton>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <ContentSkeleton>
                            <PSkeleton width="40%"></PSkeleton>
                            <PSkeleton width="60%"></PSkeleton>
                            <PSkeleton width="40%"></PSkeleton>
                            <PSkeleton width="60%"></PSkeleton>
                        </ContentSkeleton>
                    </Grid>
                    <Grid item xs={12} md={3}>
                        <ContentSkeleton>
                            <ButtonSkeleton width="60%"></ButtonSkeleton>
                            <ButtonSkeleton width="60%"></ButtonSkeleton>
                        </ContentSkeleton>
                    </Grid>
                </Grid>
                <div className="shimmerWrapper">
                    <div className="shimmerContent"></div>
                </div>
            </SkeletonWrapper>
        </Grid>
    )
}

const SkeletonAddress = () => {
    return (
        <SmallSkeletonWrapper>
            <SubtitleSkeleton width="40%"></SubtitleSkeleton>
            <BoxContent>
                <PSkeleton width="70%"></PSkeleton>
                <PSkeleton width="100%"></PSkeleton>
            </BoxContent>
            <div className="shimmerWrapper">
                <div className="shimmerContent"></div>
            </div>
        </SmallSkeletonWrapper>
    )
}

const SkeletonListItem = () => {
    return (
        <SmallSkeletonWrapper>
            <div className="shimmerWrapper">
                <div className="shimmerContent"></div>
            </div>
            <PSkeleton width="80%"></PSkeleton>
        </SmallSkeletonWrapper>
    )
}

const SkeletonProductList = () => {
    return (
        <SmallSkeletonWrapper>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <div style={{ display: 'flex', gap: '1.5rem', width: '70%' }}>
                    <TitleSkeleton width="10%" />
                    <TitleSkeleton width="45%" />
                </div>
                <TitleSkeleton width="20%" />
            </div>
            <div className="shimmerWrapper">
                <div className="shimmerContent"></div>
            </div>
        </SmallSkeletonWrapper>
    )
}

const Skeleton = (props: any) => {
    let skeleton;
    if (props.type === 'product') {
        skeleton = <SkeletonProduct />
    }
    else if (props.type === 'blog') {
        skeleton = <SkeletonBlog />
    }
    else if (props.type === 'blogDetail') {
        skeleton = <SkeletonBlogDetail />
    }
    else if (props.type === 'productDetail') {
        skeleton = <SkeletonProductDetail />
    }
    else if (props.type === 'tipsTricks') {
        skeleton = <SkeletonTipsTricks />
    }
    else if (props.type === 'news') {
        skeleton = <SkeletonNews />
    }
    else if (props.type === 'cart') {
        skeleton = <SkeletonCart />
    }
    else if (props.type === 'cartBox') {
        skeleton = <SkeletonCartBox />
    }
    else if (props.type === 'banner') {
        skeleton = <SkeletonBanner />
    }
    else if (props.type === 'productCategory') {
        skeleton = <SkeletonProductCategory />
    }
    else if (props.type === 'invoice') {
        skeleton = <SkeletonInvoice />
    }
    else if (props.type === 'address') {
        skeleton = <SkeletonAddress />
    }
    else if (props.type === 'listItem') {
        skeleton = <SkeletonListItem />
    }
    else if (props.type === 'productList') {
        skeleton = <SkeletonProductList />
    }
    return (
        <>{skeleton}</>
    )
}

const SkeletonWrapper = styled.div`
    display: flex;
    background-color: rgba(0, 0, 0, .03);
    position: relative;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
    border-radius: 20px;
    padding: 30px;

    .shimmerWrapper{
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        animation: loading 1.5s infinite;
    }

    .shimmerContent{
        background: rgba(255, 255, 255, .25);
        box-shadow: 0 0 30px rgba(255, 255, 255, 0.05);
        width: 50%;
        height: 100%;
        transform: skewX(-15deg);
    }

    @keyframes loading {
        0% { transform: translateX(-150%) }
        30% { transform: translateX(-80%) }
        100% { transform: translateX(150%) }
    }
`

const TransparentSkeletonWrapper = styled(SkeletonWrapper)`
    background: transparent;
    padding: 0px;
    border-radius: 0;
`

const NoPaddingSW = styled(SkeletonWrapper)`
    padding: 0px;
    border-radius: 0;
`

const SmallSkeletonWrapper = styled(SkeletonWrapper)`
    padding: 20px;
    border-radius: 10px;
`

const ProductSkeletonWrapper = styled(SkeletonWrapper)`
    padding: 0px;
    border-radius: 15px;
    position: relative;
    padding-top: 230px;
`

const StorySkeletonWrapper = styled(ProductSkeletonWrapper)`
    padding: 0rem 1rem;
    padding-top: 150px;
    border-radius: 0;
`

const ImageSkeleton = styled.div<{ width: string, height: string }>`
    background: rgba(0, 0, 0, .03);
    width: ${props => props.width};
    height: ${props => props.height};
`

const PISkeleton = styled(ImageSkeleton)`
    height: ${props => props.width};
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
`

const TitleSkeleton = styled.div<{ width: string }>`
    background: rgba(0, 0, 0, .03);
    min-width: 50px;
    width: ${props => props.width};
    height: 2rem;
`

const TitleSkeletonDarker = styled(TitleSkeleton)`
    height: 2.25rem;
    background: rgba(0, 0, 0, .06);
`

const ButtonSkeleton = styled(TitleSkeleton)`
    height: 2.75rem;
    margin: 0.25rem 0;
`

const SubtitleSkeleton = styled(TitleSkeleton)`
    margin-top: 0.5rem;
    height: 1.5rem;
`

const ContentSkeleton = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
`

const BoxContent = styled.div`
    padding: 1rem 0;
`

const PSkeleton = styled(TitleSkeleton)`
    margin-bottom: 1rem;
    height: 1.5rem;
    
    :last-child {
        margin: 0;
    }
`

const SmallBS = styled(ButtonSkeleton) <{ width: string, height: string }>`
    height: ${props => props.width};
    width: ${props => props.width};
`

export default Skeleton;