import { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from "react-router-dom";
import { Grid } from '@material-ui/core';

import TextInput from '../../components/form/TextInput';
import SelectOption from '../../components/form/SelectOption';
import { ButtonForm } from '../../components/form/ButtonForm';
import { AuthHeading } from '../../components/heading/Heading';
import { AuthContainer, ButtonContainer, FormContainer, HeadingImage } from '../../components/container/AuthContainer';
import { Notification } from '../../components/notification/Notification';

import { CityState, DistrictState, KelurahanState, ProvinceState } from '../../state/state';

const Register = () => {
    const [loading, setLoading] = useState(true);
    // const [status, setStatus] = useState(false);
    const [message, setMessage] = useState('');
    const [data, setData] = useState({
        username: "",
        password: "",
        name: "",
        addr_type: "contact",
        job_position: "",
        street: "",
        street2: "",
        phone: "",
        mobile: "",
        state_id: 0,
        state_name: "",
        city: "",
        city_id: 0,
        district_id: 0,
        urban_village_id: 0,
        zip: "",
        destination_coordinate: "",
    })
    const [passwordWarn, setPasswordWarn] = useState('');
    const [province, setProvince] = useState<ProvinceState[]>([]);
    const [city, setCity] = useState<CityState[]>([]);
    const [district, setDistrict] = useState<DistrictState[]>([]);
    const [kelurahan, setKelurahan] = useState<KelurahanState[]>([]);

    async function register() {
        setLoading(false);

        try {

            await axios.post(process.env.REACT_APP_MINUMMINUM_PUBLIC_API + '/customers', {
                "name": data.name,
                "username": data.username,
                "password": data.password,
                "address": {
                    "addr_type": "contact",
                    "job_position": data.job_position,
                    "street": data.street,
                    "street2": data.street2,
                    "phone": data.phone,
                    "mobile": data.mobile,
                    "zip": data.zip,
                    "city": data.city,
                    "state_id": data.state_id,
                    "city_id": data.city_id,
                    "district_id": data.district_id,
                    "urban_village_id": data.urban_village_id,
                    "destination_coordinate": ""
                }
            }).then(
                res => {
                    if (res.data.Status === true) {
                        setMessage(res.data.Message);
                        setTimeout(
                            () => window.location.replace('/login'),
                            2000
                        );
                    }
                    else {
                        setMessage(res.data.Message);
                        console.log(res.data);
                    }
                }
            )

        } catch (error) {
            // setStatus(false);
            console.log(error)
        }

        setLoading(true);
    }

    const handleInputProvince = (event: any) => {
        var index = event.target.selectedIndex;
        setData({ ...data, state_id: Number(event.target.value), state_name: event.target[index].text, city_id: 0, district_id: 0, urban_village_id: 0 });
    }

    const handleInputCity = (event: any) => {
        var index = event.target.selectedIndex;
        setData({ ...data, city: event.target[index].text, city_id: Number(event.target[index].value), district_id: 0, urban_village_id: 0 });
    }

    const handleInputDistrict = (event: any) => {
        var index = event.target.selectedIndex;
        setData({ ...data, district_id: Number(event.target[index].value), urban_village_id: 0 });
    }

    const handleInputKelurahan = (event: any) => {
        var index = event.target.selectedIndex;
        setData({ ...data, urban_village_id: Number(event.target[index].value), zip: String(kelurahan.filter((e) => (e.id === Number(event.target[index].value))).map((e) => e.postalCode)) });
    }

    const handleInputChange = (event: any) => {
        setData({ ...data, [event.target.name]: event.target.value })
    }

    const handleConfirmPassword = (event: any) => {
        if (data.password === event.target.value) {
            setPasswordWarn('success');
        }
        else {
            setPasswordWarn('warning');
        }
    }

    const handleClick = (event: any) => {
        event.preventDefault();

        if (passwordWarn === 'success') {
            if (data.city !== "") {
                register();
            }
            else {
                console.log("City belom diisi");
            }
        }
    };

    useEffect(() => {
        const getProvince = async () => {
            await axios.get(process.env.REACT_APP_MINUMMINUM_PUBLIC_API + '/state?find=all').then(res => { setProvince(res.data.result) })
        }
        getProvince();
        return () => { setProvince([]) }
    }, [])

    useEffect(() => {

        // reset selected city
        setCity([]);

        const getCity = async () => {
            await axios.post(process.env.REACT_APP_MINUMMINUM_PUBLIC_API + '/state/city_by_province', {
                "provinceName": data.state_name
            }).then(
                res => {
                    if (res.data.count === 0) {
                        setCity([]);
                    }
                    else {
                        setCity(res.data.result);
                    }
                }
            )
        }
        if (data.state_name !== "") {
            getCity()
        }
        else {
            setCity([]);
        }

        return () => { setCity([]) }
    }, [data.state_name]);

    useEffect(() => {

        // reset selected district
        setDistrict([]);

        const getCity = async () => {
            await axios.get(process.env.REACT_APP_MINUMMINUM_PUBLIC_API + '/state/district_by_city/' + data.city_id).then(
                res => {
                    if (res.data.count === 0) {
                        setDistrict([]);
                    }
                    else {
                        setDistrict(res.data.result);
                    }
                }
            )
        }

        if (data.city_id !== undefined && data.city_id !== 0) {
            getCity();
        }

        return () => { setDistrict([]) }
    }, [data.city_id]);

    useEffect(() => {

        // reset selected kelurahan
        setKelurahan([]);

        const getKelurahan = async () => {
            await axios.get(process.env.REACT_APP_MINUMMINUM_PUBLIC_API + '/state/kelurahan_by_district/' + data.district_id).then(
                res => {
                    if (res.data.count === 0) {
                        setKelurahan([]);
                    }
                    else {
                        setKelurahan(res.data.result);
                    }
                }
            )
        }

        if (data.district_id !== undefined && data.district_id !== 0) {
            getKelurahan();
        }

        return () => { setKelurahan([]) }
    }, [data.district_id]);

    return (
        <AuthContainer>
            <FormContainer>
                <HeadingImage text="Read our terms and service" />
                <div>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            {!loading && <Notification type="loading" text="Trying to register..." />}
                            {loading && message && <Notification type="info" text={message} />}
                        </Grid>
                    </Grid>
                    <form onSubmit={handleClick}>
                        <AuthHeading text="Account Credentials" />
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={4}>
                                <TextInput onInputChange={handleInputChange} required={true} name="username" label="Email" placeholder="Email Address" />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextInput onInputChange={handleInputChange} required={true} name="password" type="password" label="Password" placeholder="Password" />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <TextInput onInputChange={handleConfirmPassword} required={true} name="confirmPassword" type="password" label="Confirm Password" placeholder="Confirm Password" status={passwordWarn} />
                            </Grid>
                        </Grid>

                        <AuthHeading text="Personal Information" />
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={6}>
                                <TextInput onInputChange={handleInputChange} required={true} name="name" label="Full Name" placeholder="Full Name" />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextInput onInputChange={handleInputChange} required={false} name="job_position" label="Job Postiion" placeholder="Job Position" />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextInput onInputChange={handleInputChange} required={true} name="phone" label="Phone" placeholder="Phone" />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextInput onInputChange={handleInputChange} required={true} name="mobile" label="Mobile" placeholder="Mobile" />
                            </Grid>
                        </Grid>

                        <AuthHeading text="Address Information" />
                        <Grid container spacing={1}>
                            <Grid item xs={12} md={12}>
                                <TextInput onInputChange={handleInputChange} required={true} name="street" label="Street Address" placeholder="Street Address" />
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <TextInput onInputChange={handleInputChange} required={true} name="street2" label="Street Address 2" placeholder="Street Address 2" />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <SelectOption onInputChange={handleInputProvince} required={true} name="state_id" label="Province" data={province} value={data.state_id} />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <SelectOption onInputChange={handleInputCity} required={true} name="city" label="City" data={city.map(item => { return { name: item.cityName, id: item.id } })} value={data.city_id} />
                            </Grid>
                            <Grid item xs={12} md={4}>
                                <SelectOption onInputChange={handleInputDistrict} required={true} name="district" label="District" data={district.map(item => { return { name: item.districtName, id: item.id } })} value={data.district_id} />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <SelectOption onInputChange={handleInputKelurahan} required={true} name="kelurahan" label="Urban Village" data={kelurahan.map(item => { return { name: item.kelurahanName, id: item.id } })} value={data.urban_village_id} />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextInput onInputChange={handleInputChange} required={true} name="zip" label="Zip-code" placeholder="Zip-code" value={String(data.zip)} />
                            </Grid>
                            <Grid item xs={12}></Grid>
                            <Grid item xs={12}>
                                <ButtonContainer>
                                    <ButtonForm label="Register Now"></ButtonForm>
                                </ButtonContainer>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Link className="auth-link" to="/">Skip, i wanna explore first</Link>
                            </Grid>
                            <Grid item xs={12} md={6} className="auth-link-text-right">
                                <Link className="auth-link" to="/login">Already have account? Login here</Link>
                            </Grid>
                        </Grid>
                    </form>
                </div>
            </FormContainer>
        </AuthContainer>
    );
}

export default Register;