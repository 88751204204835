import { FC, ReactNode } from 'react';
import styled, { css } from 'styled-components';
import media from "styled-media-query";
import NumberFormat from 'react-number-format';
import { Link } from 'react-router-dom';
import { ModalDataList } from './Modal';
import { CartFullButton } from '../DynamicButton';

type ContentProps = {
    children: ReactNode,
    promo: string,
    display_name: string,
    price: number,
    promo_price: number,
    currency: string,
    url: string
}

export const ProductImageContainer: FC<{}> = ({ children }) => {
    return (
        <ImageContainer>
            {children}
        </ImageContainer>
    )
}

export const FavContainer: FC<{}> = ({ children }) => {
    return (
        <IconContainer>
            {children}
            <ToolTip>
                Add to wishlist
            </ToolTip>
        </IconContainer>
    )
}

export const ContentProduct = ({ children, promo, display_name, price, promo_price, currency, url }: ContentProps) => {
    let highlightPromo: string[] = [];
    let promoDesc: string = "";
    if (promo !== "false") {
        highlightPromo = promo.split(" ");
        for (let i = 2; i < highlightPromo.length; i++) {
            promoDesc = promoDesc + " " + highlightPromo[i];
        }
    }
    return (
        <Content>
            <PromoDesc promo={promo} promo_price={promo_price} type="small"></PromoDesc>
            <ProductTitle><Link to={url}>{display_name}</Link></ProductTitle>
            {promo_price !== 0 ?
                <div>
                    <PromoPrice type="small"><span>{currency} <NumberFormat value={price} thousandSeparator="." decimalSeparator="," displayType={'text'} /></span></PromoPrice>
                    <Price type="small">{currency} <NumberFormat value={promo_price} thousandSeparator="." decimalSeparator="," displayType={'text'} /></Price>
                </div>
                :
                <>
                    <Price type="small">{currency} <NumberFormat value={price} thousandSeparator="." decimalSeparator="," displayType={'text'} /></Price>
                </>
            }
            {children}
        </Content>
    )
}

export const ProductGridList = (props: { children: ReactNode, display_name: string, qty: number, currency: string, price: number, subtotal: number, image: string, url: string }) => {
    return (
        <ProductList>
            {props.display_name !== "Delivery Charges" ? <Link to={props.url}><SmallImage><img src={props.image} alt="" /></SmallImage></Link> : <SmallImage><img src={props.image} alt="" /></SmallImage>}
            <div className="content">
                <ModalDataList>
                    {props.display_name !== "Delivery Charges" ? <Link to={props.url}><h5>{props.display_name}</h5></Link> : <h5>{props.display_name}</h5>}
                    <p>{props.qty}x {props.currency} <NumberFormat value={props.price} thousandSeparator="." decimalSeparator="," displayType={'text'} /></p>
                </ModalDataList>
                <ModalDataList>
                    <div className="subtotal">
                        <p>{props.currency} <NumberFormat value={props.subtotal} thousandSeparator="." decimalSeparator="," displayType={'text'} /></p>
                    </div>
                </ModalDataList>
            </div>

        </ProductList>
    )
}

export const ProductCartPop = (props: { children: ReactNode, display_name: string, currency: string, price: number, image: string, url: string }) => {
    return (
        <ProductList>
            {props.display_name !== "Delivery Charges" ? <Link to={props.url}><SmallImage><img src={props.image} alt="" /></SmallImage></Link> : <SmallImage><img src={props.image} alt="" /></SmallImage>}
            <div className="content">
                <ModalDataList>
                    {props.display_name !== "Delivery Charges" ? <Link to={props.url}><h5>{props.display_name}</h5></Link> : <h5>{props.display_name}</h5>}
                    <p>{props.currency} <NumberFormat value={props.price} thousandSeparator="." decimalSeparator="," displayType={'text'} /></p>
                </ModalDataList>
                <ModalDataList>
                    <div className="subtotal">
                        <Link to="/cart">
                            <CartFullButton>View Cart</CartFullButton>
                        </Link>
                    </div>
                </ModalDataList>
            </div>

        </ProductList>
    )
}

export const PromoDesc = (props: { promo: string, promo_price: number, type: string }) => {
    let highlightPromo: string[] = [];
    let promoDesc: string = "";
    if (props.promo !== "false") {
        highlightPromo = props.promo.split(" ");
        for (let i = 2; i < highlightPromo.length; i++) {
            promoDesc = promoDesc + " " + highlightPromo[i];
        }
    }

    return (
        <ProductPromo type={props.type}>
            {props.promo !== "false" &&
                <><Highlight>{highlightPromo[0]} {highlightPromo[1]}</Highlight>{promoDesc}</>
            }
        </ProductPromo>
    )
}

export const CategoryProduct: FC<{}> = ({ children }) => {
    return (
        <CatMenu>
            {children}
        </CatMenu>
    )
}

export const OptionContainer: FC<{}> = ({ children }) => {
    return (
        <Option>
            {children}
        </Option>
    )
}

const ToolTip = styled.div`
    top: -60px;
    right: -20px;
    width: 80px;
    visibility: hidden;
    opacity: 0;
    background-color: #333;
    color: #FFF;
    text-align: center;
    padding: 5px;
    border-radius: 5px;
    position: absolute;
    z-index: 2;
    transition: all ease-in-out 0.3s;
    font-size: 0.75rem;
    line-height: 1rem;
    transform: translateY(10px);

    &:before {
        content: "";
        position: absolute;
        top: 100%;
        right: 30%;
        margin-left: -8px;
        border-width: 8px;
        border-style: solid;
        border-color: #333 transparent transparent transparent;
    }
`

const ImageContainer = styled.div`
    overflow: hidden;
    height: 200px;
    width: auto;
    display: flex;
    justify-content: center;
    align-items: center;

    ${media.lessThan("small")`
        height: 128px;
        margin-top: 15px;
    `}

    > img {
        height: 200px;
        max-width: 200px;
        object-fit: contain;
        ${media.lessThan("small")`
            max-width: 128px;
            height: 128px;
        `}
    }
`

const SmallImage = styled(ImageContainer)`
    height: 50px;
    width: 50px;

    ${media.lessThan("small")`
        height: 40px;
        margin: 0.5rem;
    `}

    > img {
        height: 50px;
        ${media.lessThan("small")`
            height: 40px;
        `}
    }
`

export const LargeImageContainer = styled(ImageContainer)`
    height: 400px;

    ${media.lessThan("small")`
        height: 300px;
    `}

    > img {
        height: 400px;
        max-width: 400px;
        
        ${media.lessThan("small")`
            max-width: 300px;
            height: 300px;
        `}
    }
`

const IconContainer = styled.div`
    position: absolute;
    top: 15px;
    right: 15px;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;

    ${media.lessThan("small")`
        top: 10px;
        right: 10px;
    `}

    :hover > svg {
        color: #B78628;
        transform: scale(1.1);
    }

    :hover > ${ToolTip} {
        visibility: visible;
        opacity: 1;
        transform: translateY(0px);
        box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 7px;
    }

    > svg {
        color: #b9b9b9;
        font-size: 1.65rem;
        transition: all ease-in-out 0.2s;
        cursor: pointer;
        ${media.lessThan("small")`
            font-size: 1.25rem;
        `}
    }

`

const Content = styled.div`
    margin-top: 30px;
    text-align: center;
    padding: 0;
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    ${media.lessThan("small")`
        margin-top: 20px;
        height: 160px;
    `}
`

const ProductTitle = styled.h5`
    margin: 0;
    font-size: 1rem;
    > a {
        color: #333;
        text-decoration: none;
    }
`

const ProductPromo = styled.span<{ type: string }>`
    display: block;
    ${props => {
        if (props.type === "small") {
            return (
                css`margin: 0rem; font-size: 0.8rem; line-height: 1.5;`
            )
        }
        else if (props.type === "large") {
            return (
                css`margin-bottom: 1.25rem; font-size: 1rem;`
            )
        }
    }}
`

const Highlight = styled.span`
    padding: 2px 5px;
    font-weight: bold;
    background-color: #F69D04;

    ${media.lessThan("medium")`
        padding: 1.5px 3px;
    `}
`

export const Price = styled.h6<{ type: string }>`
    color: #C2994B;
    ${props => {
        if (props.type === "small") {
            return (
                css`font-size: 0.9rem; margin: 0;`
            )
        }
        else if (props.type === "large") {
            return (
                css`font-size: 1.5rem; margin: 0rem 0 1rem;`
            )
        }
        else {
            return (
                css`font-size: 1.25rem; margin: 0rem 0 0.5rem;`
            )
        }
    }};
`

export const PromoPrice = styled(Price)`
    color: #a0a0a0;
    margin-bottom: 0.3rem;
    > span {
        position: relative;

        :before {
            position: absolute;
            content: "";
            right: 0;
            left: 0;
            top: 50%;
            border-top: 1px solid;
            border-color: red;
            transform:rotate(-5deg);
        }
    }
`

const CatMenu = styled.div`
    font-size: 1rem;
    display: flex;
    flex-wrap: wrap;
    gap: 15px;

    & * {
        font-family: 'Courier Prime', Courier, monospace;
        font-size: 1rem;
        background: transparent;
        color: #666;
        text-decoration: none;
        padding: 10px 20px;
        border: 2px solid #888;
        border-radius: 20px;
        cursor: pointer;

        ${media.lessThan("small")`
            padding: 8px 15px;
        `}
    }

    & :hover {
        font-weight: bold;
    }

    & .active {
        background-color: rgb(250, 230, 200, 0.5);
        border-color: #ebb114;
        color: #ebb114;
        font-weight: bold;
    }

    ${media.lessThan("medium")`
        width: 100%;
        gap: 10px;
    `}
`

const Option = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1rem 0;
    gap: 15px;

    ${media.lessThan("medium")`
        flex-direction: column;
        flex-wrap: wrap;
        align-items: center;
    `}
`

const ProductList = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0.25rem 0rem;
    padding: 8px 20px;
    gap: 10px;
    background-color: transparent;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 4px 12px;

    ${media.lessThan("small")`
        align-items: flex-start;
    `}

    .content { 
        flex-grow: 2;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 10px;
        
        ${media.lessThan("small")`
            flex-direction: column;
            align-items: flex-start;
            gap: 0px;
        `}

        .subtotal {
            white-space: nowrap;
        }
    }
`