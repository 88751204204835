import { FC, useState, useEffect, useContext, ReactElement } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { Container, Typography, Grid, Snackbar } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import CloseIcon from '@material-ui/icons/Close';

import AxiosInstance from '../utils/axiosInstance';
import { Link } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { BadgeContext } from '../state/context';

import Skeleton from '../skeleton/Skeleton';
import { ModalHeading, PageHeading } from './heading/Heading';
import { CardProductBox } from './container/Card';
import { ContentProduct, ProductCartPop, ProductImageContainer } from './container/ProductContainer';
import { ActionButton, WrapGroup } from './DynamicButton';

import { ProductState } from '../state/state';
import { ModalNoOverflow } from './container/Modal';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            paddingBottom: '30px',
            minHeight: '50vh'
        },
        iconButton: {
            borderRadius: '8px',
            padding: '5px',
        },
        deleteIcon: {
            padding: '0px',
            borderRadius: '3px',
            fontSize: '30px',
            [theme.breakpoints.down('sm')]: {
                borderRadius: '3px',
                fontSize: '20px',
            },
        },
    }),
);

const Favorite: FC<{}> = (): ReactElement => {
    const classes = useStyles();
    const cookies = new Cookies();

    const [token] = useState(cookies.get('token'));
    const [wishlist, setWishlist] = useState<ProductState[]>([]);
    const [loading, setLoading] = useState(false);
    const [changed, setChanged] = useState(false);
    const [loggedIn] = useState(cookies.get('isLoggedIn'));
    const [addedcart, setAddedcart] = useState({ open: false, message: "" });
    const [snackbar, setSnackbar] = useState({ open: false, message: "" });

    const context = useContext(BadgeContext);

    async function _deleteWishlist(id: number, display_name: string) {
        let res = await AxiosInstance.delete('/wishlist/' + id);

        if (res.status === 200 && res.data.result === true) {
            console.log(res.data.Message);
            setChanged(!changed);

            let updateBadge = await AxiosInstance.get('/customers/myprofile');

            if (updateBadge.status === 200 && updateBadge.data.count > 0) {
                context.dispatch({
                    type: 'GENERATE',
                    payload: {
                        wishlist_qty: updateBadge.data.result[0].qty_wishlist,
                        cart_qty: updateBadge.data.result[0].qty_cart,
                    }
                });
                setSnackbar({ open: true, message: display_name + " has been removed successfully from your wishlist." });
            }
        }
        else {
            console.log(res.data);
        }
    }

    async function addCart(id: number, price: number, display_name: string) {
        let res = await AxiosInstance.post('sales_detail/add_cart', {
            "productid": id,
            "price": price,
            "quantity": 1
        });

        if (res.status === 200 && res.data.Status === true) {
            console.log(res.data.Message);

            let updateBadge = await AxiosInstance.get('/customers/myprofile');

            if (updateBadge.status === 200 && updateBadge.data.count > 0) {
                context.dispatch({
                    type: 'GENERATE',
                    payload: {
                        wishlist_qty: updateBadge.data.result[0].qty_wishlist,
                        cart_qty: updateBadge.data.result[0].qty_cart,
                    }
                });
                setAddedcart({ open: true, message: display_name + " has been successfully added to your cart." });
            }
        }
        else {
            console.log(res.data);
        }
    }

    const DeleteFav = (id: number, display_name: string) => {
        _deleteWishlist(id, display_name);
    }

    useEffect(() => {
        const getProduct = async () => {
            await AxiosInstance.get('/wishlist?find=all').then(
                res => {
                    if (res.status === 200) {
                        setWishlist(res.data.result);
                    }
                    else {
                        setWishlist([])
                    }
                }
            );
            setLoading(true);
        }

        if (loggedIn) {
            getProduct();
        }
        else {
            setWishlist([]);
            setLoading(true);
        }

        return () => { setLoading(true) }
    }, [token, changed, loggedIn]);

    return (
        <Container className={classes.root}>
            <PageHeading text="Favorites" />
            <Grid container spacing={2}>
                {!loading && [1, 2, 3].map((n) => <Grid item xs={12} sm={6} md={4} key={n}><Skeleton type="product" /></Grid>)}
                {loggedIn && token ? loading && wishlist.length > 0 && wishlist.map((items, index) => (
                    <Grid item xs={12} sm={6} md={4} key={index}>
                        <CardProductBox>
                            <Link to={`/product/${items.categ_id[1].split(" / ")[1] !== undefined ? items.categ_id[1].split(" / ")[1].toLowerCase() : items.categ_id[1].toLowerCase()}/${items.id}`}>
                                <ProductImageContainer>
                                    <img src={items.image_1024_url} alt="" />
                                </ProductImageContainer>
                            </Link>
                            <ContentProduct
                                promo={String(items.promo_description)}
                                display_name={items.display_name}
                                price={items.list_price}
                                promo_price={items.promo_price}
                                currency={items.currency_id[1]}
                                url={`/product/${items.categ_id[1].split(" / ")[1] !== undefined ? items.categ_id[1].split(" / ")[1].toLowerCase() : items.categ_id[1].toLowerCase()}/${items.id}`}
                            >
                                <WrapGroup>
                                    <IconButton onClick={() => DeleteFav(items.wishlist_id, items.display_name)} aria-label="delete" disableRipple className={classes.iconButton}>
                                        <DeleteOutlineOutlinedIcon className={classes.deleteIcon} />
                                    </IconButton>
                                    <ActionButton type="cart-full" label="Add to Cart" onClick={() => addCart(items.id, items.promo_price !== 0 ? items.promo_price : items.list_price, items.display_name)} />
                                </WrapGroup>
                            </ContentProduct>
                            <ModalNoOverflow open={addedcart.open} onClose={() => setAddedcart({ open: false, message: "" })}>
                                <ModalHeading text="Successfully added" />
                                <ProductCartPop
                                    display_name={items.display_name}
                                    currency={items.currency_id[1]}
                                    price={items.promo_price !== 0 ? items.promo_price : items.list_price}
                                    image={items.image_128}
                                    url={`/product/${items.categ_id[1].split(" / ")[1] !== undefined ? items.categ_id[1].split(" / ")[1].toLowerCase() : items.categ_id[1].toLowerCase()}/${items.id}`}
                                >
                                </ProductCartPop>
                            </ModalNoOverflow>

                        </CardProductBox>
                    </Grid>
                )) : ''}
                <Grid item xs={12}>
                    {!loggedIn ?
                        <Typography variant="h6">
                            You must logged in to see your wishlist, <Link to="/login">login here</Link>
                        </Typography>
                        : ''}
                    {loading && loggedIn && wishlist.length === 0 ?
                        <Typography variant="h6">
                            Your favorite list is empty.
                        </Typography>
                        : ''}
                </Grid>
            </Grid>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                open={snackbar.open}
                autoHideDuration={5000}
                onClose={() => setSnackbar({ open: false, message: "" })}
                message={snackbar.message}
                action={
                    <>
                        <IconButton aria-label="close snackbar" disableRipple onClick={() => setSnackbar({ open: false, message: "" })}>
                            <CloseIcon fontSize="small" style={{ color: '#FFF' }} />
                        </IconButton>
                    </>
                }
            />
        </Container>
    );
}

export default Favorite;