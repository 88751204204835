import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';

export const AuthHeading = (props: { text: string }) => {
    return (
        <HeadingContainer>
            <Typography variant="h6" style={{ fontWeight: 600 }} gutterBottom>
                {props.text}
            </Typography>
        </HeadingContainer>
    )
}

export const PageHeading = (props: { text: string }) => {
    return (
        <PageHeadingContainer margin="1.25rem 0 1rem">
            <Typography variant="h5" style={{ fontWeight: 'bold', textTransform: 'capitalize' }}>
                {props.text}
            </Typography>
        </PageHeadingContainer>
    )
}

export const ProfileHeading = (props: { text: string }) => {
    return (
        <ProfileContainer>
            <Typography variant="h6" style={{ fontWeight: 600 }} gutterBottom>
                {props.text}
            </Typography>
        </ProfileContainer>
    )
}

export const ModalHeading = (props: { text: string }) => {
    return (
        <ModalContainer>
            <Typography variant="h5" style={{ fontWeight: 600 }} align="center" gutterBottom>
                {props.text}
            </Typography>
        </ModalContainer>
    )
}

export const ProductHeading = (props: { text: string }) => {
    return (
        <PageHeadingContainer margin="0rem">
            <Typography variant="h4" style={{ fontWeight: 'bold' }} >
                {props.text}
            </Typography>
        </PageHeadingContainer >
    )
}

const HeadingContainer = styled.div`
    margin: 0.75rem 0rem;
    margin-top: 2rem;
`

const PageHeadingContainer = styled(HeadingContainer) <{ margin: string }>`
    margin: ${props => props.margin};
`

const ProfileContainer = styled.div`
    padding: 0rem;
`

const ModalContainer = styled.div`
    padding: 1.25rem 0;
    position: relative;
`

export const DataHeading = styled.div`
    h6 {
        font-size: 1.25rem;
        margin: 0;
        font-weight: bold;
        line-height: 1.6;

        :first-child {
            margin-bottom: 0.5rem;
        }
    }
`
