import { FC } from 'react';
import styled from 'styled-components';
import media from "styled-media-query";

export const ButtonForm = (props: any) => {
    return (
        <Button type="submit" onClick={props.onClick}>{props.label}</Button>
    )
}

export const CancelButton = (props: any) => {
    return (
        <ButtonCancel type="reset" onClick={props.onClick}>{props.label}</ButtonCancel>
    )
}

export const GroupButtonForm: FC<{}> = ({ children }) => {
    return (
        <Group>{children}</Group>
    )
}

const Button = styled.button`
    cursor: pointer;
    padding: 15px 20px;
    margin-top: 0rem;
    background: #C98B12;
    border: none;
    border-radius: 8px;
    color: #f5eddc;
    font-family: 'Courier Prime', Courier, monospace;
    font-weight: bold;
    font-size: 1rem;
    width: 100%;
    transition: 0.2s linear;
    
    ${media.lessThan("medium")`
        padding: 10px 15px;
        margin: 0;
    `}

    &:hover {
        background: #be830e;
    }
`
const ButtonCancel = styled(Button)`
    background: #FFF;
    color: #333;
    border: 2px solid #888;
    &:hover {
        background: #f3f3f3;
    }
`

const Group = styled.div`
    display: flex;
    gap: 15px;
    justify-content: end;

    ${media.lessThan("medium")`
        margin: 1rem 0;
        flex-direction: column;
    `}
    
`