import styled from 'styled-components';
import media from "styled-media-query";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import SortIcon from '@material-ui/icons/Sort';

export const TextInput = (props:any) => {
    return(
        <InputGroup>
            <Label>{props.label}</Label>
            <Input style={{ outline: props.status === 'warning' ? '2px solid #c51212' : props.status === 'success' ? '2px solid #00973f' : '' }} required={props.required} name={props.name} onChange={props.onInputChange} value={props.value} type={props.type} placeholder={props.placeholder}/>
        </InputGroup>
    )
}

export const SelectOption = (props:any) => {
    const state = props.data;
    return(
        <InputGroup>
            <Label>{props.label}</Label>
            <DropDownIcon>
                <ArrowDropDownIcon/>
            </DropDownIcon>
            <Select name={props.name} value={props.value} onChange={props.onInputChange}>
                <option value="0" disabled>Please select {props.label}</option>
                {state.map((items: any, index: number) => (
                    <option key={index} value={items.id}>{items.name}</option>
                ))}
            </Select>
        </InputGroup>
    )
}

export const Filter = (props:any) => {
    const state = props.data;
    return(
        <FilterContainer>
            <FilterIcon>
                <SortIcon/>
            </FilterIcon>
            <FilterSelect name={props.name} value={props.value} onChange={props.onInputChange}>
                {state.map((items: any, index: number) => (
                    <option key={index} value={items.id}>{items.name}</option>
                ))}
            </FilterSelect>
        </FilterContainer>
    )
}

const InputGroup = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0.5rem 0;
    position: relative;
`

const FilterContainer = styled(InputGroup)`
    padding: 0;
    ${media.lessThan("medium")`
        width: 100%;
    `}
`

const Label = styled.label`
    margin-bottom: 0.5rem;
`

const Input = styled.input`
    border: 2px solid #999;
    border-radius: 5px;
    box-shadow: none;
    padding: 15px 20px;
    font-family: 'Courier Prime', Courier, monospace;
    font-size: 1rem;
    &:focus {
        outline: none;
    }
    ${media.lessThan("medium")`
        padding: 10px 15px;
    `}
`

const Select = styled.select`
    background-color: transparent;
    border: 2px solid #999;
    border-radius: 5px;
    box-shadow: none;
    padding: 15px 20px;
    font-family: 'Courier Prime', Courier, monospace;
    font-size: 1rem;
    -webkit-appearance: none;
    cursor: pointer;
    &:focus {
        outline: none;
    }

    ${media.lessThan("medium")`
        padding: 10px 15px;
    `}

    option {
        padding: 15px 20px;
        ${media.lessThan("medium")`
            padding: 10px 15px;
        `}
    }
`

const FilterSelect = styled(Select)`
    width: 400px;
    padding: 10px 20px;
    border-radius: 20px;
    color: #555;
    border-color: #ebb114;
    ${media.lessThan("large")`
        width: 300px;
    `}
    ${media.lessThan("medium")`
        width: 100%;
        padding: 8px 15px;
    `}
    
`

const DropDownIcon = styled.div`
    position: absolute;
    right: 10px;
    bottom: 15px;
    color: #000;
`

const FilterIcon = styled(DropDownIcon)`
    bottom: 5px;
    right: 20px;
    z-index: -1;
    color: #ebb114;
    ${media.lessThan("medium")`
        bottom: 3px;
        right: 15px;
    `}
    
`