import { FC, ReactElement, useState, useEffect } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { Container, Typography, Grid } from '@material-ui/core';

import { Link, useParams } from 'react-router-dom';
import axios from 'axios';

import StoryPageDetail from './StoryPageDetail';
import Skeleton from '../skeleton/Skeleton';
import { CategoryProduct, OptionContainer } from './container/ProductContainer';
import { PageHeading } from './heading/Heading';
import { StoryGrid, Tile } from './container/Card';

import { RouteState, StoryState } from '../state/state';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            paddingBottom: '30px',
            minHeight: '50vh'
        },
        link: {
            textDecoration: 'none',
            color: '#333',
            width: '100%',
        },
    }),
);

const StoryList = () => {
    const classes = useStyles();
    const params = useParams<RouteState>();

    const [blog, setBlog] = useState<StoryState[]>([]);
    const [loading, setLoading] = useState(false);

    let blogAPI = '';

    if (params.category === 'tipsandtricks' || params.category === 'Tips & Tricks') {
        blogAPI = process.env.REACT_APP_MINUMMINUM_PUBLIC_API + '/blogs?find=tipsandtricks'
    }
    else if (params.category === 'news' || params.category === 'News') {
        blogAPI = process.env.REACT_APP_MINUMMINUM_PUBLIC_API + '/blogs?find=news'
    }
    else if (params.category === 'video' || params.category === 'Top Video') {
        blogAPI = process.env.REACT_APP_MINUMMINUM_PUBLIC_API + '/blogs?find=top_video'
    }

    useEffect(() => {
        const getBlog = async () => {
            try {
                await axios.get(blogAPI).then(res => { setBlog(res.data.result) });
            }
            catch (error) {
                setBlog([]);
                console.log(error);
            }
            setLoading(true);
        }
        getBlog();
        return () => {
            setLoading(false);
            setBlog([]);
        }
    }, [blogAPI]);

    return (
        <Grid container spacing={2}>
            {!loading && [1, 2, 3, 4].map((n) => <Grid key={n} item xs={6} md={3}><Skeleton type="blog" /></Grid>)}
            {loading && blog.length > 0 ?
                blog.map((items) => (
                    <Grid item xs={12} sm={6} md={3} key={items.id}>
                        <Tile>
                            <Link to={`/story/${items.blog_id[1] === 'Tips & Tricks' ? 'tipsandtricks' : items.blog_id[1] === 'News' ? 'news' : 'video'}/${items.id}`} className={classes.link}>
                                <StoryGrid
                                    cover={items.cover_properties === "" ? "https://static-mm.awan.id/web/image/" : items.cover_properties}
                                    title={items.display_name}
                                    __last_update={items.__last_update}
                                    description={items.description}
                                    content={items.content}
                                >
                                </StoryGrid>
                            </Link>
                        </Tile>
                    </Grid>
                )) : ''}
                {loading && blog.length === 0 && <Grid item xs={6}><Typography variant="subtitle1">There is no blog in this parameter</Typography></Grid>}
        </Grid>
    )
}

const StoryContent = () => {
    const params = useParams<RouteState>();

    return (
        <div>
            <OptionContainer>
                <CategoryProduct>
                    <Link to="/story/tipsandtricks" className={params.category === 'tipsandtricks' || params.category === 'Tips & Tricks' ? 'active' : ''}>
                        Tips And Tricks
                    </Link>
                    <Link to="/story/news" className={params.category === 'news' || params.category === 'News' ? 'active' : ''}>
                        News
                    </Link>
                    <Link to="/story/video" className={params.category === 'video' || params.category === 'Top Video' ? 'active' : ''}>
                        Video
                    </Link>
                </CategoryProduct>
            </OptionContainer>
            {params.id && params.id && <StoryPageDetail />}
            {params.category && params.id == null && <StoryList />}
        </div>
    )
}

const StoryPage: FC<{}> = (): ReactElement => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Container>
                <PageHeading text="The Story About Booze" />
                <StoryContent />
            </Container>
        </div>
    );
}

export default StoryPage;