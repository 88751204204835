import { ReactNode } from 'react';
import styled from 'styled-components';
import media from "styled-media-query";
import Typography from '@material-ui/core/Typography';

export const InvoiceTab = (props: { date: string, invoiceID: string }) => {
	return (
		<Tab>
			<Typography variant="h6" style={{ fontWeight: 'bold' }}>
				{props.date} <span> - {props.invoiceID}</span>
			</Typography>
		</Tab>
	)
}

export const InvoiceContent = (props: { children: ReactNode }) => {
	return (
		<Content>
			{props.children}
		</Content>
	)
}

export const InvoiceImageContainer = (props: { children: ReactNode }) => {
	return (
		<ImageContainer>
			{props.children}
		</ImageContainer>
	)
}

export const InvoiceData = (props: { children: ReactNode }) => {
	return (
		<DataInvoice>
			{props.children}
		</DataInvoice>
	)
}

const Tab = styled.div`
	width: 100%;
	border-bottom: 2px solid #F4C749;
	color: #B78628;
	padding-bottom: 0.5rem;

	span {
		color: #888
	}
`

const Content = styled.div`
	padding: 15px 20px;
	display: flex;
	gap: 30px;
	justify-content: flex-end;

	${media.lessThan("medium")`
		align-items: flex-end;
		gap: 20px;
	`}

	${media.lessThan("small")`
        flex-direction: column;
		align-items: center;
		padding: 20px 0;
    `}
`

const ImageContainer = styled.div`
	height: 250px;
	width: 250px;
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: flex-end;

	img {
		max-width: 200px;
		max-height: 200px;
		object-fit: contain;
	}

	${media.lessThan("medium")`
		height: 120px;
		width: 120px;
		img { 
			height: 120px;
		}
	`}
`

const DataInvoice = styled.div`
	flex-grow: 2;
	width: calc(100% - 250px);
	display: flex;
	justify-content: space-between;
	align-items: flex-end;

	${media.lessThan("medium")`
		width: calc(100% - 120px);
		font-size: 1rem;
		flex-grow: 0;
	`}

	${media.lessThan("small")`
		flex-direction: column;
		align-items: center;
	`}

	.invoiceData {
		
		${media.lessThan("small")`
			margin: 0.5rem 0;
		`}

		.invoiceStatus, .invoicePrice {
			margin-top: 1.5rem;
			color: #333;
			font-size: 1.25rem;

			${media.lessThan("medium")`
				font-size: 1rem;
			`}

			label {
				font-weight: bold;
			}
			
			h6 {
				margin: 0;
				line-height: 1.6;
				font-size: 1.25rem;
				color: #C2994B;
				text-transform: capitalize;
				${media.lessThan("medium")`
					font-size: 1rem;
				`}
			}

			${media.lessThan("medium")`
				margin-top: 0.5rem;
			`}

			${media.lessThan("small")`
				text-align: center;
			`}

		}
	}

	
`

export const ButtonGroupInvoice = styled.div`
	width: 250px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 15px;

	${media.lessThan("medium")`
		width: 150px;
        gap: 10px;
		margin-right: 0px;
    `}

	${media.lessThan("small")`
		margin: 0.5rem 0;
	`}

	button {
		margin-left: auto;
		margin-right: auto;
	}
`