import { ReactNode } from 'react';
import styled from 'styled-components';
import media from "styled-media-query";

import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

export const DataList = (props: { children: ReactNode }) => {
    return (
        <Item>
            {props.children}
        </Item>
    )
}

export const AgreementInformation = (props: { children: ReactNode }) => {
    return (
        <InfoBox>
            <InfoOutlinedIcon style={{ marginRight: '10px' }}></InfoOutlinedIcon>
            <p>
                {props.children}
            </p>
        </InfoBox>
    )
}

export const TrackingBox = (props: { children: ReactNode, last: boolean }) => {
    return (
        <Tracking>
            <div className="icon">
                <FiberManualRecordIcon />
                {!props.last && <div className="line" />}
            </div>
            {props.children}
        </Tracking>
    )
}

const Item = styled.div`
    font-size: 1rem;
    line-height: 1.5;
    margin: 0rem 0;
    display: flex;
    justify-content: space-between;

    * {
        margin: 0;
    }

    h4 {
        font-size: 1.25rem;
        font-weight: bold;
    }
    
    h5 {
        font-size: 1rem;
        font-weight: normal;
        margin: 0.5rem 0;
    }

    h6 { 
        font-size: 1rem;
        margin: 0;
    }

    p {
        font-weight: bold;
        font-size: 1rem;
        color: #C2994B;
        white-space: nowrap;
    }
`

export const HighligthDataList = styled(Item)`
    margin-top: 1rem;
    padding-top: 1rem;
    border-top: 1.5px solid #b8b8b8;

    h5 { font-weight: bold; }
`

const InfoBox = styled.div`
    display: flex;
    color: #333;
    background: rgba(134, 134, 134, 0.15);
    border-radius: 7px;
    padding: 20px 15px;
    margin-top: 1rem;

    p { margin: 0; line-height: 1.6; font-size: 0.875rem; }
`

export const ContentCart = styled.div`
    display: flex;
    justify-content: flex-start;
    gap: 30px;
    
    ${media.lessThan("small")`
        gap: 10px;
    `}

    .content {
        width: 100%;
        max-width: calc(100% - 230px);

        ${media.lessThan("medium")`
            max-width: calc(100% - 180px);
        `}

        ${media.lessThan("small")`
            max-width: calc(100% - 110px);
        `}

        a { color: #000; text-decoration: none; display: block }

        h4 {
            margin: 0;
            font-size: 1.25rem;
            line-height: 1.25;
        }
        
        .desc {
            font-size: 0.875rem;
            font-family: Courier Prime;
            font-weight: 500;
            line-height: 1.57;
            padding: 0.5rem 0;
            margin: 0;

            ${media.lessThan("small")`
                font-size: 0.85rem;
                line-height: 1.3;
            `}
        }
    }
`

export const ImageCart = styled.div`
    width: 200px;
    height: 200px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;

    ${media.lessThan("medium")`
        width: 150px;
        height: 150px;
    `}

    ${media.lessThan("small")`
        width: 100px;
        height: 100px;
    `}

    img {
        width: auto;
        height: 200px;

        ${media.lessThan("medium")`
            height: 150px;
        `}
        
        ${media.lessThan("small")`
            height: 100px;
        `}
    }
`

export const CartAction = styled.div`
    display: flex;
    margin: 1rem 0;
    gap: 5px;
    align-items: center;

    ${media.lessThan("small")`
        margin: 0.5rem 0;
    `}

    .counter {
        margin-left: 1rem;
        display: flex;
        align-items: center;
        
        ${media.lessThan("small")`
            margin-left: 0rem;
        `}

        p { 
            margin: 0 1rem;
            
            ${media.lessThan("small")`
                margin: 0 0.5rem;
            `}
        }
    }
`

const Tracking = styled.div`
    padding: 10px;
    padding-left: 50px;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 7px;
    background-color: transparent;
    position: relative;

    .icon {
        position: absolute;
        left: 15px;
        color: #F4C749;

        svg {
            font-size: 27px;
        }
    }

    .line {
        position: absolute;
        left: 12px;
        top: 10px;
        width: 4px;
        height: 80px;
        background-color: #F4C749;
    }
`

