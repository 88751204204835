import { Container } from "@material-ui/core";
import ProductsList from "../../components/ProductList";

const SearchPage = () => {
	return (
		<Container>
			<ProductsList type="search" />
		</Container>
	)
}

export default SearchPage;