import { ReactNode, FC, useState, useEffect } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { Fade } from '@material-ui/core';
import styled from 'styled-components';
import { CardModal } from './Card';

type Props = {
    open: boolean,
    onClose: any,
    children: ReactNode,
}

export const Modal = ({ open, children, onClose }: Props) => {
    const [openModal, setOpenModal] = useState(open);

    useEffect(() => {
        setOpenModal(open);
    }, [open]);

    if (openModal) {
        document.body.style.overflow = 'hidden';
    }
    else {
        document.body.style.overflow = 'unset';
    }

    return (
        <>
            <Fade in={openModal}>
                <ModalBackground>
                    <Overlay onClick={() => onClose()} />
                    <CardModal>
                        <CloseContainer onClick={() => onClose()}>
                            <CloseIcon />
                        </CloseContainer>
                        {children}
                    </CardModal>
                </ModalBackground>
            </Fade>
        </>
    )
}

export const ModalNoOverflow = ({ open, children, onClose }: Props) => {
    const [openModal, setOpenModal] = useState(open);

    useEffect(() => {
        setOpenModal(open);
    }, [open]);

    return (
        <>
            <Fade in={openModal}>
                <ModalBackground>
                    <Overlay onClick={() => onClose()} />
                    <CardModal>
                        <CloseContainer onClick={() => onClose()}>
                            <CloseIcon />
                        </CloseContainer>
                        {children}
                    </CardModal>
                </ModalBackground>
            </Fade>
        </>
    )
}

export const ModalBody: FC<{}> = ({ children }) => {
    return (
        <Body>
            {children}
        </Body>
    )
}

export const ModalDataList = (props: { children: ReactNode }) => {
    return (
        <Item>
            {props.children}
        </Item>
    )
}

const ModalBackground = styled.div`
    position: fixed;
    z-index: 3;
    left: 0;
    top: 0;
    width: 100%; 
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`

const Overlay = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
`

const CloseContainer = styled.div`
    z-index: 1;
    position: absolute;
    right: 1.5rem;
    top: 1.5rem;
    cursor: pointer;
    display: flex;
    width: auto;
    justify-content: center;
    align-items: center;
`

const Body = styled.div`
    margin-bottom: 2rem;
`

const Item = styled.div`
    font-weight: bold;
    font-size: 1rem;
    line-height: 1.5;
    margin: 0.5rem 0;

    * {
        margin: 0;
    }
    
    h5 {
        font-size: 1rem;
        line-height: 1.25;
    }

    a {
        text-decoration: none;
        color: #000;
    }

    p {
        font-size: 1rem;
        color: #C2994B;
        line-height: 1.25;
    }
`