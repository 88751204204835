import React, { createContext } from "react";

interface AuthState {
    isAuthenticated: boolean,
    full_name: string,
    user_id: string,
    access_token: string,
}
interface AuthAction {
    type: string,
    payload: AuthState;
}
interface BadgeState {
    wishlist_qty: number,
    cart_qty: number,
}

export const InitialBadgeState: BadgeState = {
    wishlist_qty: 0,
    cart_qty: 0,
}
interface BadgeAction {
    type: string,
    payload: BadgeState;
}

export const InitialState: AuthState = {
    isAuthenticated: false,
    full_name: '',
    user_id: '',
    access_token: '',
}

export const AuthContext = createContext<{
    state: AuthState,
    dispatch: React.Dispatch<AuthAction>
}>({
    state: InitialState,
    dispatch: () => undefined,
})

export const BadgeContext = createContext<{
    state: BadgeState,
    dispatch: React.Dispatch<BadgeAction>
}>({
    state: InitialBadgeState,
    dispatch: () => undefined,
})

export const AuthReducer = (state: AuthState, action: AuthAction) => {
    switch (action.type) {
        case 'LOGIN':
            localStorage.setItem("access_token", action.payload.access_token);
            localStorage.setItem("full_name", action.payload.full_name);
            return {
                ...state,
                isAuthenticated: true,
                full_name: action.payload.full_name,
                user_id: action.payload.user_id,
                access_token: action.payload.access_token,
            }
        case 'LOGOUT':
            localStorage.clear();
            return {
                ...state,
                isAuthenticated: false,
                full_name: action.payload.full_name,
                user_id: action.payload.user_id,
                access_token: action.payload.access_token,
            }
        default:
            return state;
    }
}

export const BadgeReducer = (state: BadgeState, action: BadgeAction) => {
    switch (action.type) {
        case 'GENERATE':
            return {
                ...state,
                wishlist_qty: action.payload.wishlist_qty,
                cart_qty: action.payload.cart_qty,
            }
        default:
            return state;
    }
}