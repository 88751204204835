import styled from 'styled-components';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

const SelectOption = (props:any) => {
    const state = props.data;
    return(
        <InputGroup>
            <Label>{props.label}</Label>
            <SelectBox>
                <Select name={props.name} value={props.value} onChange={props.onInputChange}>
                    <option value="0" disabled hidden>Please select {props.label}</option>
                    {state.map((items: any, index: number) => (
                        <option key={index} value={items.id}>{items.name}</option>
                    ))}
                </Select>
                <DropDownIcon>
                    <ArrowDropDownIcon/>
                </DropDownIcon>
            </SelectBox>
        </InputGroup>
        
    )
}

const InputGroup = styled.div`
    display: flex;
    flex-direction: column;
    margin: 0.25rem 0;
    position: relative;
`

const Label = styled.label`
    margin-bottom: 0.75rem;
`

const Select = styled.select`
    position: relative;
    z-index: 2;
    width: 100%;
    border: none;
    background: transparent;
    box-shadow: none;
    padding: 15px 20px;
    color: #888;
    font-family: 'Courier Prime', Courier, monospace;
    font-size: 1rem;
    -webkit-appearance: none;
    cursor: pointer;

    &:focus {
        outline: none;
    }

    option {
        color: #888;
        background: #000;
        padding: 15px 20px;
    }
`

const SelectBox = styled.div`
    background: #000;
    border-radius: 8px;
    position: relative;
`

const DropDownIcon = styled.div`
    height: 24px;
    width: 24px;
    position: absolute;
    right: 10px;
    bottom: 13px;
    color: #888;
    z-index: 0;
`

export default SelectOption;
