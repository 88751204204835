import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';

export const DownloadAppNav = () => {
    const title = {
        lineHeight: 'inherit',
        color: '#333',
    }

    const icon = {
        fontSize: '1.25rem',
    }

    return (
        <AppBar>
            <Container>
                <Toolbar>
                    <DownloadLink onClick={() => window.open("/")}>
                        <PhoneAndroidIcon style={icon} />
                        <Typography style={title} variant="subtitle2">
                            Download minummminum.com app
                        </Typography>
                    </DownloadLink>
                    <Typography style={title} variant="subtitle2">
                        Let MINUMMINUM.COM refill it everytime
                    </Typography>
                    <Typography style={title} variant="subtitle2">
                        Become minumminum.com reseller
                    </Typography>
                </Toolbar>
            </Container>
        </AppBar>
    )
}

const AppBar = styled.header`
    position: static;
    background: linear-gradient(to right, #B78628, #FCC201, #FCC201, #B78628);
`

const Toolbar = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 10px;
    min-height: 40px;
`

const DownloadLink = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    & * {
        margin-right: 5px;
        :hover {
            text-decoration: underline;
        }
    }
`

